import { render, staticRenderFns } from "./gameLevel.vue?vue&type=template&id=278a5780&scoped=true"
import script from "./gameLevel.vue?vue&type=script&lang=js"
export * from "./gameLevel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278a5780",
  null
  
)

export default component.exports