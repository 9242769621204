<template>
  <div>
    <div style="margin-bottom: 15px;display: flex;flex-direction:row-reverse">
      <a-button style="width: 200px;" type="primary" ghost @click="addCache.show=true">添加等级</a-button>
    </div>
    <a-table :columns="columns" :data-source="data" bordered>
      <template
          v-for="col in ['level','item_name']"
          :slot="col"
          slot-scope="text, record"
      >
        <div :key="col">
          <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record.id, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="() => save(record.id)" style="margin-right: 5px">保存</a>
             <a-popconfirm title="确定删除?" okText="确定" cancelText="取消" @confirm="() => deleteR(record.id)">
                      <a style="margin-right: 5px">删除</a>
              </a-popconfirm>

          <a-popconfirm title="确定取消?" okText="确定" cancelText="取消" @confirm="() => cancel(record.id)">
            <a>取消</a>
          </a-popconfirm>
        </span>
          <span v-else>
          <a :disabled="editingKey !== ''" @click="() => edit(record.id)">编辑</a>
        </span>
        </div>
      </template>
    </a-table>


    <a-modal v-model="addCache.show" title="等级添加" @ok="addLevel(1)" okText="添加"
             cancelText="取消"
             @cancel="addLevel(0)">

      <div style="margin-bottom: 16px">
        <a-input addon-before="等级:" v-model="addCache.data.level"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="物品:" v-model="addCache.data.item_name"/>
      </div>
    </a-modal>

  </div>
</template>

<script>
const key = '123'
export default {
  name: "gameLevel",
  data() {
    return {
      columns: [
        {
          title: '等级',
          dataIndex: 'level',
          key: 'level',
          scopedSlots: {customRender: 'level'},
        },
        {
          title: '物品名称',
          dataIndex: 'item_name',
          key: 'item_name',
          scopedSlots: {customRender: 'item_name'},
        },

        {
          title: '设置',
          dataIndex: 'operation',
          width: '180px',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      data: [],
      copy_data: [],
      editingKey: '',
      cacheData: '',

      addCache: {
        show: false,
        data: {
          level: '',
          item_name: '',
        }
      }
    }
  },
  methods: {
    addLevel(v) {
      if (!v) {
        this.addCache.show = false
        this.addCache.data = {
          level: '',
          item_name: '',
        }
        return
      }

      this.$message.loading({content: '上传中', key, duration: 0})
      this.$axios.post('/Admin/Api/GameLevel/addGameLevel', {
        ...this.addCache.data
      }).then(v => {
        this.$message.success({content: v.data.msg, key, duration: 1})
        if (this.data.length != 0) {
          this.data.unshift({
            // id: this.data[this.data.length - 1].id + 1,
            id: `${this.data[this.data.length - 1].id + 1}缓存数据`,
            ...this.addCache.data
          })
          this.copy_data = this.data
          this.cacheData = this.data.map(item => ({...item}))
        } else {
          this.data.unshift({
            id: 1,
            ...this.addCache.data
          })
          this.copy_data = this.data
          this.cacheData = this.data.map(item => ({...item}))
        }


        this.addCache.data = {
          level: '',
          item_name: '',
        }
        console.log(1)
        this.addCache.show = false

      }, r => {
        this.$message.error({content: r.msg, key, duration: 1})
        this.addCache.data = {
          level: '',
          item_name: '',
        }
        this.addCache.show = false

      })
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }

    },

    deleteR(id) {
      const idd = `${id}`
      this.$message.success({content: '上传中', key, duration: 0})
      if (idd.includes('缓存数据')){
        this.$message.error({content: '缓存数据刷新后才可删除', key, duration: 1})
return
      }
      this.$axios.post('/Admin/Api/GameLevel/delGameLevel', {
        id: id
      }).then(() => {
        const data = [...this.data]
        this.data = data.filter((v) => v.id !== id)
        this.cancel(id)
        this.$message.success({content: '删除成功', key, duration: 1})
      })
    },

    edit(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.id);
      this.editingKey = key;

      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },

    save(key) {
      this.$message.loading({content: '上传中', key, duration: 0})
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.id);
      const targetCache = newCacheData.find(item => key === item.id);

      if (target && targetCache) {

        this.$axios.post("/Admin/Api/GameLevel/changeGameLevel", {
          id: key,
          level: target.level,
          item_name: target.item_name
        }).then(v => {
          this.$message.success({content: v.data.msg, key, duration: 1})
          delete target.editable;
          this.data = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
          this.copy_data = this.data
        }, r => {
          this.$message.error({content: r.data, key, duration: 1})
          this.cancel(key)
        })

      }
      this.editingKey = '';
    },

    cancel(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.id);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.id));
        delete target.editable;
        this.data = this.copy_data
      }

    }
  },
  created() {
    this.$axios.post('/Admin/Api/GameLevel/getGameLevelList', {
      page: 1,
      pageCount: 1000,
    }).then(v => {
      const temp = JSON.parse(v.data.data)
      this.data = temp.list
      console.log(temp.list[temp.list.length - 1])
      this.copy_data = temp.list
      this.cacheData = temp.list.map(item => ({...item}))
    })
  }
}
</script>

<style scoped>

</style>